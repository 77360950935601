.vocbtn {
  position: fixed;
  bottom: 50%;
  right: 0;
  z-index: 3;
  /* background-color: '#e5e5e5'; */
}

/* if screen width is smaller than 480px, move voc button to bottom of screen */
@media screen and (max-width: 480px) {
  .vocbtn {
    position: fixed;
    bottom: 0;
  }
}
