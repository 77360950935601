.lm-Modal .lm-Modal-wrapper:before {
  height: 10px;
}

.lm-ButtonClose {
  top: 0 !important;
}

#errorMsg {
  text-align: center;
  margin-left: 0.5em;
}
