/* file status and info labels */
.filepond--file {
  background-color: #ffd000;
}

/* The drop or browse label */
.filepond--drop-label {
  color: white;
}

/* top row file status */
.filepond--file-status-main {
  font-size: 0.85em !important;
  color: #d32f2f;
  font-weight: bold;
}

/* bottom row file status */
.filepond--file-status-sub {
  color: #d32f2f;
  font-weight: bold;
  font-size: 0.725em !important;
  opacity: 5 !important;
}

/* File name */
.filepond--file-info-main {
  font-weight: bold;
  color: black;
}

/* File size */
.filepond--file-info-sub {
  color: black;
}

/* any buttons on file strip */
.filepond--file-action-button {
  background-color: #28a3af;
}

/* the background color of the filepond drop area */
.filepond--panel-root {
  background-color: #1a1446;
}

/* error state color DOESNT WORK */
[data-filepond-item-state*='error'] .filepond--item-panel,
[data-filepond-item-state*='invalid'] .filepond--item-panel {
  background-color: red !important;
}
