.formBorder {
  border: 1px solid #000000;
  width: 110%;
}

.borderMargin {
  margin: 1rem 1.5rem;
}

.notification-window {
  width: 55%;
}

@media screen and (max-width: 800px) {
  .notification-window {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .notification-bullet {
    margin: 0.5em 0;
  }
}
