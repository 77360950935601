/*For the div containing the icon and name*/
#newNames {
  background-color: #ffd000;
  border-radius: 15px;
  margin-left: 1px;
  margin-right: auto;
}

/*For the icon*/
#deleteBtn {
  width: 35px;
  height: 35px;
  margin-top: -7px;
}

/*For the name that is inputted*/
#name {
  margin-top: 0.2rem;
  line-height: 100%;
  text-align: left;
  margin-left: 5px;
  margin-right: 10px;
}

/* for Expanded Checkbox 3 and 4's addName button*/
#addNameBtn {
  margin-top: 0;
  height: 3.5rem;
  padding: 7px;
  width: 100%;
}
