@import url('https://fonts.googleapis.com/css?family=PT+Sans');
html,
body,
#app {
  height: 100%;
}

.name {
  margin-top: 25px;
}
