.faq.component {
  margin-top: 60px;
}
.faq.component .lmig-Accordion--isOpen button {
  background-color: rgb(255,208,0);
  font-weight: bold;
}

.maintenance-banner {
  padding: 20px;
  margin: 40px auto 0;
  border: solid 3px #FF5733;
  border-radius: 10px;
  color: white;
}
