#commentBox {
  width: 100%;
}

.initialBox {
  width: 100% !important;
  border: solid 1px #919191;
}

.initialBox:focus {
  outline: none;
  border: solid 2px #28a3af !important;
}

.mouseOver {
  border: solid 1px #28a3af;
}

.mouseOver:focus {
  outline: none;
  border: solid 2px #28a3af !important;
}

.mouseExit {
  border: solid 1px #919191;
}

.requiredBox {
  width: 100% !important;
  border: solid 1px #e92243;
}

.requiredBox:focus {
  outline: none;
  border: solid 2px #e92243 !important;
}

.blur {
  border: solid 1px #919191;
  background-color: white;
}
