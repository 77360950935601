.listElements {
  margin-bottom: 0.5rem;
}

.list {
  margin-bottom: 2rem;
}

.request-type-grid button {
  width: 100%;
}